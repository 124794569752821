import { render, staticRenderFns } from "./MedicalContentTemplateNew.vue?vue&type=template&id=206da82d&scoped=true&"
import script from "./MedicalContentTemplateNew.vue?vue&type=script&lang=js&"
export * from "./MedicalContentTemplateNew.vue?vue&type=script&lang=js&"
import style0 from "./MedicalContentTemplateNew.vue?vue&type=style&index=0&id=206da82d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "206da82d",
  null
  
)

export default component.exports