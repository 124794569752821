<template>
  <div class="estimate-template-input-form">
    <div class="row name">
      <text-input-form
        :requiredFlg="true"
        :placeholder="'15文字まで'"
        :maxlength="15"
        :styles="{ width: '350px' }"
        v-model="estimateTemplate.name"
        >テンプレート名</text-input-form
      >
    </div>
    <div class="row content">
      <multiple-text-input-form
        :styles="textAreaStyle"
        v-model="estimateTemplate.content"
        >内容</multiple-text-input-form
      >
    </div>
  </div>
</template>

<script>
import TextInputForm from '@/components/parts/molecules/TextInputForm'
import MultipleTextInputForm from '@/components/parts/molecules/MultipleTextInputForm'

export default {
  name: 'EstimateTemplateInputForm',

  components: {
    TextInputForm,
    MultipleTextInputForm
  },

  props: {
    estimateTemplate: { type: Object }
  },

  data() {
    return {
      textAreaStyle: {
        width: '100%',
        height: 'auto',
        minWidth: '350px',
        minHeight: '200px',
        fontSize: '13px',
        resize: 'both'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.estimate-template-input-form {
  > .row {
    ::v-deep .text-input-form .heading,
    ::v-deep .multiple-text-input-form .heading {
      width: 240px;
    }
    ::v-deep .multiple-text-input-form .body {
      max-width: 600px;
    }
  }
  > .row ~ .row {
    margin-top: 25px;
  }
}
</style>
