<template>
  <div class="medical-content-template-show">
    <div class="list-link">
      <base-back-link @click="gotoListPage" />
    </div>
    <div class="title">診療内容テンプレート詳細</div>
    <div class="contents">
      <div class="button-area">
        <base-button-small-white
          class="button"
          v-if="lookOnlyFlg === 0"
          @click="gotoEditPage"
          >編集</base-button-small-white
        >
        <base-button-small-red
          class="button"
          v-if="lookOnlyFlg === 0"
          @click="openDeletePopup"
          >削除</base-button-small-red
        >
      </div>
      <div class="basic-items-area">
        <div class="heading">基本項目</div>
        <div class="basic-items">
          <div class="item">
            <div class="label">テンプレート名</div>
            <div class="value">
              <div class="text">{{ medicalContentTemplate.name }}</div>
            </div>
          </div>
          <div class="item">
            <div class="label">内容</div>
            <div class="value medical-content-template">
              <div class="text">
                <editor-content class="editor" :editor="contentEditor" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <announce-popup
      v-if="popupFlg"
      :title="title"
      :buttons="buttons"
      :disabled="waitFlg"
      :type="type"
      @cancel="cancelDelete"
      @decision="decideDelete"
      @close="closePopup"
      >{{ popupMessage }}</announce-popup
    >
  </div>
</template>

<script>
import BaseBackLink from '@/components/parts/atoms/BaseBackLink'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import BaseButtonSmallRed from '@/components/parts/atoms/BaseButtonSmallRed'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { mapGetters } from 'vuex'
import { Editor, EditorContent } from '@tiptap/vue-2'
import TextStyle from '@tiptap/extension-text-style'
import FontFamily from '@tiptap/extension-font-family'
import Underline from '@tiptap/extension-underline'
import { Color } from '@tiptap/extension-color'
import {
  configureStarterKit,
  FontSize,
  BackgroundColor
} from '@/utils/rich_text_editor_custom_options'

export default {
  name: 'MedicalContentTemplateShow',

  components: {
    BaseBackLink,
    BaseButtonSmallWhite,
    BaseButtonSmallRed,
    AnnouncePopup,
    EditorContent
  },

  props: {
    id: { type: Number, default: 0 }
  },

  data() {
    return {
      contentEditor: null,
      popupFlg: false,
      waitFlg: false,
      title: '',
      buttons: [],
      type: '',
      popupMessage: ''
    }
  },

  computed: {
    ...mapGetters({
      getMedicalContentTemplate: 'medicalContentTemplates/getDataById',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    medicalContentTemplate() {
      return this.getMedicalContentTemplate(this.id)
    }
  },

  created() {
    this.setEditor()
  },

  watch: {
    'medicalContentTemplate.content': function() {
      this.setEditor()
    }
  },

  methods: {
    setEditor() {
      if (this.contentEditor) {
        this.contentEditor.destroy()
        this.contentEditor = null
      }
      this.$nextTick(() => {
        const editor = new Editor({
          content: this.medicalContentTemplate.content,
          extensions: [
            configureStarterKit(),
            TextStyle,
            Color,
            FontSize,
            BackgroundColor,
            FontFamily,
            Underline
          ],
          //editable: falseの設定だとjestでエラーが発生します。
          //解決方法が見つからず、また上記の設定をしなくてもテストする内容は変わらないため、
          //テスト環境ではtrueにします
          editable: process.env.NODE_ENV === 'test' ? true : false
        })
        this.contentEditor = editor
      })
    },
    gotoListPage() {
      this.$router.push({ name: 'medical-content-templates' })
    },
    gotoEditPage() {
      this.$router.push({ path: `${this.id}/edit` })
    },
    openDeletePopup() {
      this.type = 'alert'
      this.title = '注意'
      this.buttons = ['削除しない', '削除する']
      this.popupMessage = '削除してもよろしいですか？'
      this.popupFlg = true
    },
    cancelDelete() {
      this.popupFlg = false
    },
    async decideDelete() {
      this.waitFlg = true
      const res = await this.$store.dispatch(
        'medicalContentTemplates/delete',
        this.id
      )
      if (res === true) {
        this.type = 'success'
        this.title = '完了'
        this.buttons = ['閉じる']
        this.popupMessage = '削除しました'
      } else {
        this.type = 'failure'
        this.title = '失敗'
        this.buttons = ['閉じる']
        this.popupMessage = '削除に失敗しました'
      }
      this.waitFlg = false
    },
    closePopup() {
      this.popupFlg = false
      if (this.type === 'success') this.gotoListPage()
    }
  }
}
</script>

<style lang="scss" scoped>
.medical-content-template-show {
  > .list-link {
    margin-left: -26px;
  }
  > .title {
    font-size: 20px;
    font-weight: bold;
    border-bottom: 1px solid #{$light-grey};
    padding-bottom: 20px;
    margin-top: 20px;
  }
  > .contents {
    margin-top: 20px;
    margin-bottom: 24px;
    > .button-area {
      display: flex;
      justify-content: flex-end;
      > .button {
        margin-left: 20px;
      }
    }
    > .basic-items-area {
      margin-top: 16px;
      > .heading {
        font-size: 15px;
        font-weight: bold;
        border-bottom: 1px solid #{$light-grey};
        padding-bottom: 20px;
      }
      > .basic-items {
        margin-top: 25px;
        > .item {
          display: flex;
          width: 100%;
          > .label {
            width: 240px;
            font-size: 15px;
          }
          > .value {
            flex: 1;
            display: flex;
            align-items: center;
            &:not(.medical-content-template) {
              font-size: 13px;
            }
            > .text {
              flex: 1;
              width: 0;
              font-display: swap;
              white-space: pre-wrap;
              word-wrap: break-word;
              > .editor {
                ::v-deep .ProseMirror > p {
                  margin: 0;
                }
              }
            }
          }
        }
        > .item ~ .item {
          margin-top: 25px;
        }
      }
    }
  }
}
</style>
