<template>
  <div class="medical-content-template-edit">
    <validation-observer class="validation-observer" v-slot="{ invalid }">
      <div class="title">診療内容テンプレート編集</div>
      <div class="contents">
        <div class="content-wrap">
          <div class="heading">基本項目</div>
          <div class="content">
            <medical-content-template-input-form
              class="input-form"
              :data="medicalContentTemplate"
              @invalid-content="val => (invalidContent = val)"
            />
          </div>
        </div>
        <div class="button-wrap">
          <base-button-medium-white class="button" @click="openShowPage"
            >戻る</base-button-medium-white
          >
          <base-button-medium-orange
            class="button"
            v-if="lookOnlyFlg === 0"
            :disabled="invalid || waitFlg || invalidContent"
            @click="editData"
            >登録</base-button-medium-orange
          >
        </div>
      </div>
      <announce-popup
        v-if="alertFlg"
        :title="title"
        :buttons="buttons"
        :type="type"
        @close="closeAlert"
        >{{ popupMessage }}</announce-popup
      >
      <unsaved-leave-popup />
    </validation-observer>
  </div>
</template>

<script>
import MedicalContentTemplateInputForm from '@/components/parts/organisms/MedicalContentTemplateInputForm'
import BaseButtonMediumWhite from '@/components/parts/atoms/BaseButtonMediumWhite'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import { ValidationObserver } from 'vee-validate'
import { mapGetters } from 'vuex'

export default {
  name: 'MedicalContentTemplateEdit',

  components: {
    MedicalContentTemplateInputForm,
    BaseButtonMediumWhite,
    BaseButtonMediumOrange,
    AnnouncePopup,
    UnsavedLeavePopup,
    ValidationObserver
  },

  mixins: [CheckInputDifference],

  props: {
    id: { type: Number, default: 0 }
  },

  data() {
    return {
      medicalContentTemplate: {},
      waitFlg: false,
      alertFlg: false,
      type: '',
      title: '',
      popupMessage: '',
      buttons: ['閉じる'],
      richTextEditorFlg: true,
      invalidContent: false
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg',
      getMedicalContentTemplate: 'medicalContentTemplates/getDataById'
    }),
    content() {
      return this.$store.getters['richTextEditor/getEditor'] === null
        ? ''
        : this.$store.getters['richTextEditor/getContent']
    }
  },

  watch: {
    content: function(newVal) {
      if (this.richTextEditorFlg) {
        this.mixinInputData.content = newVal
      }
    }
  },

  created() {
    const target = this.getMedicalContentTemplate(this.id)
    this.medicalContentTemplate = {
      id: target.id,
      name: target.name,
      content: target.content
    }
  },

  mounted() {
    this.mixinInputData = this.medicalContentTemplate
    setTimeout(() => {
      this.mixinSetInitialData()
    }, 500)
  },

  methods: {
    openShowPage() {
      this.$router.push({
        path: `/settings/template/medical-content-templates/${this.id}`
      })
    },
    async editData() {
      this.waitFlg = true
      const res = await this.$store.dispatch(
        'medicalContentTemplates/update',
        this.medicalContentTemplate
      )
      if (res === true) {
        this.type = 'success'
        this.title = '完了'
        this.popupMessage = '編集しました'
        this.mixinSetInitialData()
      } else if (res === 'no data in clinic' || res === 'no data') {
        this.type = 'failure'
        this.title = '失敗'
        this.popupMessage =
          '編集に失敗しました。\n編集中の診療内容テンプレートは既に削除されています。'
      } else {
        this.type = 'failure'
        this.title = '失敗'
        this.popupMessage = '編集に失敗しました'
      }
      this.waitFlg = false
      this.alertFlg = true
    },
    closeAlert() {
      this.alertFlg = false
      if (this.type === 'success') {
        this.$store.dispatch('petorelu/okLeave')
        this.openShowPage()
      } else if (
        this.popupMessage ===
        '編集に失敗しました。\n編集中の診療内容テンプレートは既に削除されています。'
      ) {
        this.$store.dispatch('petorelu/okLeave')
        this.$router.push('/settings/template/medical-content-templates')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.medical-content-template-edit {
  > .validation-observer {
    > .title {
      font-size: 20px;
      font-weight: bold;
      border-bottom: 1px solid #{$light-grey};
      padding-bottom: 20px;
    }
    > .contents {
      margin-top: 20px;
      > .content-wrap {
        > .heading {
          font-size: 15px;
          font-weight: bold;
          border-bottom: 1px solid #{$light-grey};
          padding-bottom: 20px;
        }
        > .content {
          margin-top: 25px;
        }
      }
      > .button-wrap {
        display: flex;
        justify-content: center;
        margin: 60px 0;
        > .button {
          margin: 0 16px;
        }
      }
    }
  }
}
</style>
