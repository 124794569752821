<template>
  <div class="medical-content-template-new">
    <validation-observer class="validation-observer" v-slot="{ invalid }">
      <div class="title">診療内容テンプレート登録</div>
      <div class="contents">
        <div class="content-wrap">
          <div class="heading">基本項目</div>
          <div class="content">
            <medical-content-template-input-form
              class="input-form"
              :data="medicalContentTemplate"
              @invalid-content="val => (invalidContent = val)"
            />
          </div>
        </div>
        <div class="button-wrap">
          <base-button-medium-white class="button" @click="openListPage"
            >戻る</base-button-medium-white
          >
          <base-button-medium-orange
            class="button"
            v-if="lookOnlyFlg === 0"
            :disabled="invalid || waitFlg || invalidContent"
            @click="createData"
            >登録</base-button-medium-orange
          >
        </div>
      </div>
      <announce-popup
        v-if="alertFlg"
        :title="title"
        :buttons="buttons"
        :type="type"
        @close="closeAlert"
        >{{ popupMessage }}</announce-popup
      >
      <unsaved-leave-popup />
    </validation-observer>
  </div>
</template>

<script>
import MedicalContentTemplateInputForm from '@/components/parts/organisms/MedicalContentTemplateInputForm'
import BaseButtonMediumWhite from '@/components/parts/atoms/BaseButtonMediumWhite'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import { ValidationObserver } from 'vee-validate'
import { mapGetters } from 'vuex'

export default {
  name: 'MedicalContentTemplateNew',

  components: {
    MedicalContentTemplateInputForm,
    BaseButtonMediumWhite,
    BaseButtonMediumOrange,
    AnnouncePopup,
    UnsavedLeavePopup,
    ValidationObserver
  },

  mixins: [CheckInputDifference],

  data() {
    return {
      medicalContentTemplate: {
        name: '',
        content: '<p></p>'
      },
      waitFlg: false,
      alertFlg: false,
      type: '',
      title: '',
      popupMessage: '',
      buttons: ['閉じる'],
      richTextEditorFlg: true,
      invalidContent: false
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    content() {
      return this.$store.getters['richTextEditor/getEditor'] !== null
        ? this.$store.getters['richTextEditor/getContent']
        : ''
    }
  },

  watch: {
    content: function(newVal) {
      if (this.richTextEditorFlg) {
        this.mixinInputData.content = newVal
      }
    }
  },

  mounted() {
    this.mixinInputData = this.medicalContentTemplate
    this.mixinSetInitialData()
  },

  methods: {
    openListPage() {
      this.$router.push({ name: 'medical-content-templates' })
    },
    async createData() {
      this.waitFlg = true
      const res = await this.$store.dispatch(
        'medicalContentTemplates/create',
        this.medicalContentTemplate
      )
      if (res === true) {
        this.type = 'success'
        this.title = '完了'
        this.popupMessage = '新規登録しました'
        this.mixinSetInitialData()
      } else {
        this.type = 'failure'
        this.title = '失敗'
        this.popupMessage = '登録に失敗しました'
      }
      this.waitFlg = false
      this.alertFlg = true
    },
    closeAlert() {
      this.alertFlg = false
      if (this.type === 'success') {
        this.$store.dispatch('petorelu/okLeave')
        this.openListPage()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.medical-content-template-new {
  > .validation-observer {
    > .title {
      font-size: 20px;
      font-weight: bold;
      border-bottom: 1px solid #{$light-grey};
      padding-bottom: 20px;
    }
    > .contents {
      margin-top: 20px;
      > .content-wrap {
        > .heading {
          font-size: 15px;
          font-weight: bold;
          border-bottom: 1px solid #{$light-grey};
          padding-bottom: 20px;
        }
        > .content {
          margin-top: 25px;
        }
      }
      > .button-wrap {
        display: flex;
        justify-content: center;
        margin: 60px 0;
        > .button {
          margin: 0 16px;
        }
      }
    }
  }
}
</style>
