<template>
  <div class="medical-content-template-input-form">
    <text-input-form
      :maxlength="15"
      :requiredFlg="true"
      :styles="{ width: '350px' }"
      v-model="data.name"
      >テンプレート名</text-input-form
    >
    <div class="content">
      <div v-if="!popupFlg" class="label" data-test="label">内容</div>
      <div class="text-editor-set">
        <rich-text-editor-tool-bar :content="data.content" class="tool-bar" />
        <rich-text-editor
          :content="data.content"
          :editorContentStyles="editorContentStyles"
          @invalid-content="val => $emit('invalid-content', val)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TextInputForm from '@/components/parts/molecules/TextInputForm'
import RichTextEditorToolBar from '@/components/parts/organisms/RichTextEditorToolBar'
import RichTextEditor from '@/components/parts/organisms/RichTextEditor'
export default {
  name: 'MedicalContentTemplateInputForm',

  components: {
    TextInputForm,
    RichTextEditorToolBar,
    RichTextEditor
  },

  props: {
    data: { type: Object },
    popupFlg: { type: Boolean, default: false }
  },

  computed: {
    editorContentStyles() {
      return this.popupFlg
        ? { '--min-height': '280px', '--border': '1px solid #cecece' }
        : { '--min-height': '500px', '--border': '1px solid #cecece' }
    }
  }
}
</script>

<style lang="scss" scoped>
.medical-content-template-input-form {
  ::v-deep .text-input-form .heading {
    width: 240px;
  }
  > .content {
    display: flex;
    margin-top: 20px;
    > .label {
      min-width: 240px;
      font-size: 15px;
    }
    > .text-editor-set {
      > .tool-bar {
        margin-bottom: 10px;
      }
    }
  }
}
</style>
