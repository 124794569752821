<template>
  <div class="estimate-template-edit">
    <validation-observer class="validation-observer" v-slot="{ invalid }">
      <div class="title">見積書備考テンプレート編集</div>
      <div class="contents">
        <div class="content-wrap">
          <div class="heading">基本項目</div>
          <div class="content">
            <estimate-template-input-form
              class="input-form"
              :estimateTemplate="estimateTemplate"
            />
          </div>
        </div>
        <div class="button-wrap">
          <base-button-medium-white class="button" @click="goToShowPage"
            >戻る</base-button-medium-white
          >
          <base-button-medium-orange
            class="button"
            v-if="lookOnlyFlg === 0"
            :disabled="invalid || waitFlg"
            @click="updateData"
            >登録</base-button-medium-orange
          >
        </div>
      </div>
      <announce-popup
        v-if="popupFlg"
        :title="title"
        :buttons="buttons"
        :type="type"
        @close="closePopup"
        >{{ popupMessage }}</announce-popup
      >
    </validation-observer>
    <unsaved-leave-popup />
  </div>
</template>

<script>
import EstimateTemplateInputForm from '@/components/parts/organisms/EstimateTemplateInputForm'
import BaseButtonMediumWhite from '@/components/parts/atoms/BaseButtonMediumWhite'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import { ValidationObserver } from 'vee-validate'
import { mapGetters } from 'vuex'

export default {
  name: 'EstimateTemplateEdit',

  components: {
    EstimateTemplateInputForm,
    BaseButtonMediumWhite,
    BaseButtonMediumOrange,
    AnnouncePopup,
    UnsavedLeavePopup,
    ValidationObserver
  },

  mixins: [CheckInputDifference],

  props: {
    id: { type: Number }
  },

  data() {
    return {
      estimateTemplate: {},
      popupFlg: false,
      type: '',
      title: '',
      popupMessage: '',
      buttons: ['閉じる'],
      waitFlg: false,
      noDataFlg: false
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg',
      estimateTemplateById: 'estimateTemplates/getDataById'
    })
  },

  created() {
    this.estimateTemplate = { ...this.estimateTemplateById(this.id) }
    this.mixinInputData = this.estimateTemplate
    this.mixinSetInitialData()
  },

  methods: {
    goToShowPage() {
      this.$router.push({ name: 'estimate-template-show', params: this.id })
    },
    async updateData() {
      this.waitFlg = true
      const result = await this.$store.dispatch(
        'estimateTemplates/update',
        this.estimateTemplate
      )
      if (result === true) {
        this.type = 'success'
        this.title = '完了'
        this.popupMessage = '編集しました'
        this.mixinSetInitialData()
      } else {
        this.type = 'failure'
        this.title = '失敗'
        if (result === 'no data' || result === 'no data in clinic') {
          this.popupMessage =
            '編集に失敗しました。\n編集中の見積書備考テンプレートは既に削除されています。'
          this.noDataFlg = true
        } else {
          this.popupMessage = '編集に失敗しました'
        }
      }
      this.waitFlg = false
      this.popupFlg = true
    },
    closePopup() {
      this.popupFlg = false
      if (this.type === 'success') {
        this.$store.dispatch('petorelu/okLeave')
        this.goToShowPage()
      }
      if (this.noDataFlg) {
        this.$store.dispatch('petorelu/okLeave')
        this.$router.push({ name: 'estimate-templates' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.estimate-template-edit {
  > .validation-observer {
    > .title {
      font-size: 20px;
      font-weight: bold;
      border-bottom: 1px solid #{$light-grey};
      padding-bottom: 20px;
    }
    > .contents {
      margin-top: 20px;
      > .content-wrap {
        > .heading {
          font-size: 15px;
          font-weight: bold;
          border-bottom: 1px solid #{$light-grey};
          padding-bottom: 20px;
        }
        > .content {
          margin-top: 25px;
        }
      }
      > .button-wrap {
        display: flex;
        justify-content: center;
        margin: 60px 0;
        > .button {
          margin: 0 16px;
        }
      }
    }
  }
}
</style>
