<template>
  <div class="rich-text-editor-tool-bar" v-if="editor">
    <div class="select-wrapper" title="フォント">
      <select
        class="select-box"
        :value="fontFamilyValue"
        @change="changeFontFamily"
      >
        <option
          v-for="f in fontFamilyArray"
          :style="{ 'font-family': f.fontFamily }"
          :key="f.key"
          >{{ f.fontFamily }}</option
        >
      </select>
    </div>
    <div class="select-wrapper" title="フォントサイズ">
      <select
        class="select-box"
        :value="fontSizeValue"
        @change="changeFontSize"
      >
        <option v-for="f in fontSizeArray" :key="f.key">{{
          f.fontSize
        }}</option>
      </select>
    </div>
    <div
      :class="{ icon: true, disabled: !canUndo }"
      v-shortkey="['ctrl', 'z']"
      @shortkey="undoContent"
      @click="undoContent"
      data-test="undo"
      title="元に戻す(Ctrl+Z)"
    >
      <font-awesome-icon class="icon-font" :icon="undoIcon" />
    </div>
    <div
      :class="{ icon: true, disabled: !canRedo }"
      v-shortkey="['ctrl', 'y']"
      @shortkey="redoContent"
      @click="redoContent"
      data-test="redo"
      title="やり直し(Ctrl+Y)"
    >
      <font-awesome-icon class="icon-font" :icon="redoIcon" />
    </div>
    <div
      class="icon"
      v-shortkey="['ctrl', 'b']"
      @shortkey="toggleBold"
      @click="toggleBold"
      data-test="bold"
      title="太字(Ctrl+B)"
    >
      <font-awesome-icon
        :class="{ 'icon-font': true, 'is-active': editor.isActive('bold') }"
        :icon="boldIcon"
      />
    </div>
    <div
      class="icon"
      v-shortkey="['ctrl', 'i']"
      @shortkey="toggleItalic"
      @click="toggleItalic"
      data-test="italic"
      title="斜体(Ctrl+I)"
    >
      <font-awesome-icon
        :class="{ 'icon-font': true, 'is-active': editor.isActive('italic') }"
        :icon="italicIcon"
      />
    </div>
    <div
      class="icon"
      v-shortkey="['ctrl', 's']"
      @shortkey="toggleStrike"
      @click="toggleStrike"
      data-test="strike"
      title="打ち消し線(Ctrl+S)"
    >
      <font-awesome-icon
        :class="{ 'icon-font': true, 'is-active': editor.isActive('strike') }"
        :icon="strikeIcon"
      />
    </div>
    <div
      class="icon"
      v-shortkey="['ctrl', 'u']"
      @shortkey="toggleUnderline"
      @click="toggleUnderline"
      data-test="underline"
      title="下線(Ctrl+U)"
    >
      <font-awesome-icon
        :class="{
          'icon-font': true,
          'is-active': editor.isActive('underline')
        }"
        :icon="underlineIcon"
      />
    </div>
    <div class="color-set" title="テキストの色">
      <div
        class="color-label"
        :style="fontColorLabelStyle"
        @click="openFontColorChart"
        data-test="font-color-label"
      >
        A
      </div>
      <base-color-chart
        class="color-chart"
        v-if="isActiveFontColorChart"
        :colorSets="richTextEditorColorSet"
        @click="changeFontColor"
      />
    </div>
    <div class="color-set" title="ハイライトの色">
      <div @click="openBackgroundColorChart" data-test="background-color-label">
        <font-awesome-icon
          class="background-color-label"
          :style="backgroundColorLabelStyle"
          :icon="highlighterIcon"
        />
      </div>
      <base-color-chart
        class="color-chart"
        v-if="isActiveBackgroundColorChart"
        :colorSets="richTextEditorColorSet"
        @click="changeBackgroundColor"
      />
    </div>
    <div
      v-if="isActiveFontColorChart || isActiveBackgroundColorChart"
      class="cover"
      @click="closeColorChart"
      data-test="cover"
    ></div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faBold,
  faItalic,
  faStrikethrough,
  faUnderline,
  faUndo,
  faRedo,
  faHighlighter
} from '@fortawesome/free-solid-svg-icons'
import { mapGetters } from 'vuex'
import BaseColorChart from '@/components/parts/atoms/BaseColorChart'
import { richTextEditorColorSet } from '@/utils/color_library'

export default {
  name: 'RichTextEditorToolBar',

  components: {
    FontAwesomeIcon,
    BaseColorChart
  },

  props: {
    content: { type: String, default: '<p></p>' }
  },

  data() {
    return {
      boldIcon: faBold,
      italicIcon: faItalic,
      strikeIcon: faStrikethrough,
      underlineIcon: faUnderline,
      undoIcon: faUndo,
      redoIcon: faRedo,
      highlighterIcon: faHighlighter,
      tmpFontFamily: 'Noto Sans JP, sans-serif',
      tmpFontSize: '16px',
      tmpFontColor: '',
      tmpBackgroundColor: '',
      isActiveFontColorChart: false,
      isActiveBackgroundColorChart: false,
      richTextEditorColorSet: richTextEditorColorSet,
      fontFamilyArray: [
        { key: 1, fontFamily: 'Noto Sans JP, sans-serif' },
        { key: 2, fontFamily: 'Arial' },
        { key: 3, fontFamily: 'Comic Sans MS' },
        { key: 4, fontFamily: 'Courier New' },
        { key: 5, fontFamily: 'Georgia' },
        { key: 6, fontFamily: 'Impact' },
        { key: 7, fontFamily: 'Times New Roman' },
        { key: 8, fontFamily: 'Trebuchet' },
        { key: 9, fontFamily: 'Verdana' },
        { key: 10, fontFamily: 'メイリオ' },
        { key: 11, fontFamily: 'ＭＳ Ｐゴシック' },
        { key: 12, fontFamily: 'ＭＳ Ｐ明朝' }
      ]
    }
  },

  computed: {
    ...mapGetters({
      editor: 'richTextEditor/getEditor'
    }),
    fontSizeArray() {
      return new Array(21).fill('').map((v, i) => {
        return { fontSize: i * 2 + 10 + 'px', key: i + 1 }
      })
    },
    textStyle() {
      return this.editor ? this.editor.getAttributes('textStyle') : {}
    },
    fontFamilyValue() {
      return this.tmpFontFamily !== ''
        ? this.tmpFontFamily
        : this.textStyle.fontFamily
        ? this.textStyle.fontFamily
        : 'Noto Sans JP, sans-serif'
    },
    fontSizeValue() {
      return this.tmpFontSize !== ''
        ? this.tmpFontSize
        : this.textStyle.fontSize
        ? this.textStyle.fontSize
        : '16px'
    },
    fontColorLabelStyle() {
      return this.tmpFontColor !== ''
        ? { 'border-bottom': `4px solid ${this.tmpFontColor}` }
        : this.textStyle.color
        ? { 'border-bottom': `4px solid ${this.textStyle.color}` }
        : { 'border-bottom': '4px solid #000000' }
    },
    backgroundColorLabelStyle() {
      return this.tmpBackgroundColor !== ''
        ? { 'border-bottom': `4px solid ${this.tmpBackgroundColor}` }
        : this.textStyle.backgroundColor
        ? { 'border-bottom': `4px solid ${this.textStyle.backgroundColor}` }
        : { 'border-bottom': '4px solid #ffffff' }
    },
    canUndo() {
      return this.editor ? this.editor.can().undo() : false
    },
    canRedo() {
      return this.editor ? this.editor.can().redo() : false
    }
  },

  watch: {
    content: function(newVal) {
      if (newVal === '<p></p>') this.resetFontStyle()
    }
  },

  created() {
    window.addEventListener('keydown', this.keydown)
  },

  mounted() {
    this.resetFontStyle()
  },

  methods: {
    resetFontStyle() {
      this.$store.dispatch(
        'richTextEditor/fontFamily',
        'Noto Sans JP, sans-serif'
      )
      this.$store.dispatch('richTextEditor/fontSize', '16px')
      this.$store.dispatch('richTextEditor/fontColor', '#000000')
      this.$store.dispatch('richTextEditor/backgroundColor', '')
    },
    changeFontFamily(e) {
      this.tmpFontFamily = e.target.value
      this.$store.dispatch('richTextEditor/fontFamily', e.target.value)
    },
    changeFontSize(e) {
      this.tmpFontSize = e.target.value
      this.$store.dispatch('richTextEditor/fontSize', e.target.value)
    },
    toggleBold() {
      this.$store.dispatch('richTextEditor/bold')
    },
    toggleItalic() {
      this.$store.dispatch('richTextEditor/italic')
    },
    toggleStrike() {
      this.$store.dispatch('richTextEditor/strike')
    },
    toggleUnderline() {
      this.$store.dispatch('richTextEditor/underline')
    },
    openFontColorChart() {
      this.isActiveFontColorChart = !this.isActiveFontColorChart
      this.isActiveBackgroundColorChart = false
    },
    changeFontColor(color) {
      this.tmpFontColor = color
      this.$store.dispatch('richTextEditor/fontColor', color)
      this.isActiveFontColorChart = false
    },
    openBackgroundColorChart() {
      this.isActiveBackgroundColorChart = !this.isActiveBackgroundColorChart
      this.isActiveFontColorChart = false
    },
    closeColorChart() {
      this.isActiveFontColorChart = false
      this.isActiveBackgroundColorChart = false
    },
    changeBackgroundColor(color) {
      this.tmpBackgroundColor = color
      color = color === '#ffffff' ? '' : color
      this.$store.dispatch('richTextEditor/backgroundColor', color)
      this.isActiveBackgroundColorChart = false
    },
    keydown() {
      this.tmpFontFamily = ''
      this.tmpFontSize = ''
      this.tmpFontColor = ''
      this.tmpBackgroundColor = ''
    },
    undoContent() {
      this.$store.dispatch('richTextEditor/undo')
    },
    redoContent() {
      this.$store.dispatch('richTextEditor/redo')
    }
  }
}
</script>

<style lang="scss" scoped>
.rich-text-editor-tool-bar {
  display: flex;
  height: 36px;
  > .select-wrapper {
    margin-right: 10px;
    display: inline-block;
    position: relative;
    background-color: #{$white};
    border-radius: 5px;
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 8px;
      width: 6px;
      height: 6px;
      border: 0px;
      border-bottom: solid 2px #{$pumpkin};
      border-right: solid 2px #{$pumpkin};
      -ms-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      margin-top: -6px;
    }
    &.disabled {
      &:after {
        border-bottom: solid 2px #{$brown-gray};
        border-right: solid 2px #{$brown-gray};
      }
    }
    > .select-box {
      cursor: pointer;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      display: block;
      position: relative;
      z-index: 1;
      height: 36px;
      max-width: 117px;
      padding: 0 26px 0 8px;
      background: transparent;
      border: solid 2px #{$light-grey};
      border-radius: 5px;
      font-size: 13px;
      > .option-default-none {
        display: none;
      }
      > .placeholder {
        display: none;
      }
      &:disabled {
        pointer-events: none;
        background-color: #{$light-grey};
      }
      &.disabled-color-white {
        &:disabled {
          opacity: 1;
          background-color: #{$white};
          color: #{$greyish-brown};
        }
      }
    }
    > .select-box::-ms-expand {
      display: none;
    }
  }
  > .icon {
    line-height: 36px;
    margin: 0 7px;
    @include hover();
    > .icon-font {
      width: 20px;
      height: 20px;
      font-size: 20px;
    }
    > .is-active {
      background: #{$ice-blue};
      color: #{$blue};
    }
  }
  > .disabled {
    opacity: 0.3;
    pointer-events: none;
  }
  > .color-set {
    width: 20px;
    line-height: 36px;
    margin: 0 7px;
    position: relative;
    > .color-label {
      text-align: center;
      height: 29px;
      width: 20px;
      line-height: 30px;
      box-sizing: border-box;
      font-size: 21px;
      font-weight: bold;
      color: #3e3e3e;
      margin-bottom: 10px;
      text-decoration: none;
      @include hover();
    }
    > div {
      > .background-color-label {
        position: absolute;
        top: 5px;
        margin-top: 1px;
        height: 19px;
        width: 20px;
        font-size: 17px;
        color: #3e3e3e;
        text-decoration: none;
        @include hover();
      }
    }
    > .color-chart {
      position: absolute;
      top: 30px;
      z-index: 1100;
    }
  }
  > .cover {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 1;
    z-index: 1000;
  }
}
</style>
