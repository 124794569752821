<template>
  <div class="rich-text-editor">
    <div
      class="rich-text-editor-form"
      :style="richTextEditorStyles"
      :class="{ 'prevent-touch': selectFlg }"
    >
      <editor-content
        class="editor"
        :editor="editor"
        :style="editorContentStyles"
        @click-examination-result-table="
          tableInfo => $emit('click-examination-result-table', tableInfo)
        "
        @click-upload-image="image => $emit('click-upload-image', image)"
        @edit-medical-content-image="editMedicalContentImage"
        @unsaved-resized-image="$emit('unsaved-resized-image')"
        @prevent-touch="preventTouch"
        data-test="editor"
      />
    </div>
    <span
      ><div class="error" data-test="err-msg">{{ errorMsg }}</div></span
    >
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import TextStyle from '@tiptap/extension-text-style'
import FontFamily from '@tiptap/extension-font-family'
import Underline from '@tiptap/extension-underline'
import { Color } from '@tiptap/extension-color'
import {
  configureStarterKit,
  FontSize,
  BackgroundColor,
  createVueRichTextEditorExaminationTable,
  createVueRichTextEditorImage,
  createVueRichTextEditorMedicalContentImage
} from '@/utils/rich_text_editor_custom_options'
import Image from '@tiptap/extension-image'
import { mapGetters } from 'vuex'

export default {
  name: 'RichTextEditor',

  components: {
    EditorContent
  },

  data() {
    return {
      selectFlg: false
    }
  },

  props: {
    content: { type: String, default: '<p></p>' },
    richTextEditorStyles: {
      type: Object,
      default: function() {
        return { width: '600px', height: '500px' }
      }
    },
    editorContentStyles: {
      type: Object,
      default: function() {
        return { '--min-height': '500px', '--border': '1px solid #cecece' }
      }
    }
  },

  computed: {
    ...mapGetters({
      editor: 'richTextEditor/getEditor'
    }),
    errorMsg() {
      const content = this.content
      if (/[\uD800-\uDBFF][\uDC00-\uDFFF]/.test(content)) {
        this.$emit('invalid-content', true)
        return '使用できない文字が含まれています'
      } else {
        this.$emit('invalid-content', false)
        return ''
      }
    }
  },

  created() {
    this.setEditor()
  },

  methods: {
    setEditor() {
      const VueRichTextEditorExaminationTable = createVueRichTextEditorExaminationTable(
        {}
      )
      const VueRichTextEditorImage = createVueRichTextEditorImage({})
      const VueRichTextEditorMedicalContentImage = createVueRichTextEditorMedicalContentImage(
        {}
      )
      const editor = new Editor({
        content: this.content,
        extensions: [
          configureStarterKit(),
          TextStyle,
          Color,
          FontSize,
          BackgroundColor,
          Image,
          VueRichTextEditorExaminationTable,
          VueRichTextEditorImage,
          VueRichTextEditorMedicalContentImage,
          FontFamily,
          Underline
        ]
      })
      this.$store.dispatch('richTextEditor/set', editor)
      this.$emit('set-to-initial-medical-content')
    },
    resetEditor() {
      this.$store.dispatch('richTextEditor/reset')
      this.setEditor()
    },
    editMedicalContentImage(medicalContentImageId) {
      this.$emit('edit-medical-content-image', medicalContentImageId)
    },
    preventTouch(selectFlg) {
      this.selectFlg = selectFlg
    }
  }
}
</script>

<style lang="scss" scoped>
.rich-text-editor {
  > .rich-text-editor-form {
    overflow: auto;
    &.prevent-touch {
      touch-action: none;
    }
    > .editor {
      transform-origin: 0 0;
      height: 100%;
      ::v-deep .ProseMirror {
        width: 100%;
        min-height: var(--min-height);
        border: var(--border);
        background-color: #{$white};
        padding: 5px;
        box-sizing: border-box;
        > p {
          margin: 0;
        }
        font-display: swap;
      }
    }
  }
  @include validate-message();
}
</style>
