<template>
  <div class="estimate-template-show">
    <div class="list-link">
      <base-back-link @click="goToListPage" />
    </div>
    <div class="title">見積書備考テンプレート詳細</div>
    <div class="contents">
      <div class="content buttons">
        <base-button-small-white
          class="button"
          v-if="lookOnlyFlg === 0"
          @click="goToEditPage"
          >編集</base-button-small-white
        >
        <base-button-small-red
          class="button"
          v-if="lookOnlyFlg === 0"
          @click="openDeletePopup"
          >削除</base-button-small-red
        >
      </div>
      <div class="content detail">
        <div class="heading">基本項目</div>
        <div class="detail">
          <show-data-list
            :displayKeys="displayKeys"
            :keys="keys"
            :data="estimateTemplate"
          />
        </div>
      </div>
    </div>
    <announce-popup
      v-if="popupFlg"
      :title="title"
      :buttons="buttons"
      :disabled="waitFlg"
      :type="type"
      @cancel="cancelDelete"
      @decision="decideDelete"
      @close="closePopup"
      >{{ popupMessage }}</announce-popup
    >
  </div>
</template>

<script>
import BaseBackLink from '@/components/parts/atoms/BaseBackLink'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import BaseButtonSmallRed from '@/components/parts/atoms/BaseButtonSmallRed'
import ShowDataList from '@/components/parts/organisms/ShowDataList.vue'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { mapGetters } from 'vuex'

export default {
  name: 'EstimateTemplateShow',

  components: {
    BaseBackLink,
    BaseButtonSmallWhite,
    BaseButtonSmallRed,
    ShowDataList,
    AnnouncePopup
  },

  props: {
    id: { type: Number }
  },

  data() {
    return {
      displayKeys: ['テンプレート名', '内容'],
      keys: ['name', 'content'],
      popupFlg: false,
      type: '',
      title: '',
      popupMessage: '',
      buttons: [],
      waitFlg: false
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg',
      estimateTemplateById: 'estimateTemplates/getDataById'
    }),
    estimateTemplate() {
      const estimateTemplate = this.estimateTemplateById(this.id)
      return estimateTemplate || {}
    }
  },

  methods: {
    goToListPage() {
      this.$router.push({ name: 'estimate-templates' })
    },
    goToEditPage() {
      this.$router.push({ path: `${this.id}/edit` })
    },
    openDeletePopup() {
      this.type = 'alert'
      this.title = '注意'
      this.buttons = ['削除しない', '削除する']
      this.popupMessage = '削除してもよろしいですか？'
      this.popupFlg = true
    },
    cancelDelete() {
      this.popupFlg = false
    },
    async decideDelete() {
      this.waitFlg = true
      const result = await this.$store.dispatch(
        'estimateTemplates/delete',
        this.id
      )
      if (result === true) {
        this.type = 'success'
        this.title = '完了'
        this.buttons = ['閉じる']
        this.popupMessage = '削除しました'
      } else {
        this.type = 'failure'
        this.title = '失敗'
        this.buttons = ['閉じる']
        this.popupMessage = '削除に失敗しました'
      }
      this.waitFlg = false
    },
    closePopup() {
      this.popupFlg = false
      if (this.type === 'success') this.goToListPage()
    }
  }
}
</script>

<style lang="scss" scoped>
.estimate-template-show {
  width: 100%;
  text-align: left;
  > .list-link {
    margin-left: -26px;
  }
  > .title {
    margin-top: 20px;
    border-bottom: 1px solid #{$light-grey};
    padding-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
  }
  > .contents {
    margin-top: 20px;
    margin-bottom: 24px;
    > .buttons {
      display: flex;
      justify-content: flex-end;
      > .button {
        margin-left: 20px;
      }
    }
    > .detail {
      margin-top: 16px;
      > .heading {
        font-size: 15px;
        font-weight: bold;
        border-bottom: 1px solid #{$light-grey};
        padding-bottom: 20px;
      }
      > .detail {
        margin-top: 25px;
      }
    }
  }
}
</style>
