<template>
  <div class="medical-content-templates">
    <div class="content">
      <div class="area buttons" v-if="!mixinSortModeFlg">
        <router-link :to="'medical-content-templates/new'">
          <base-button-register v-if="lookOnlyFlg === 0" :text="'新規登録'" />
        </router-link>
        <base-sort-button
          class="button"
          v-if="lookOnlyFlg === 0"
          @click="setSortMode"
        />
      </div>
      <div class="area buttons" v-else>
        <base-button-small-white class="button" @click="cancelSortMode"
          >キャンセル</base-button-small-white
        >
        <base-button-small-orange
          class="button"
          v-if="lookOnlyFlg === 0"
          @click="updateOrder"
          :disabled="waitFlg"
          >並び順登録</base-button-small-orange
        >
      </div>
      <div class="area list">
        <div class="heading" data-test="heading">
          診療内容テンプレート一覧<span v-if="mixinSortModeFlg">{{
            mixinSortModeMessage
          }}</span>
        </div>
        <list-table
          :directOrderFlg="true"
          :selectBoxFlg="false"
          :headerData="headers"
          :propertyData="properties"
          :bodyData="displayData"
          :sortModeFlg="mixinSortModeFlg"
          :headerItemStyleData="styles"
          :bodyItemStyleData="styles"
          @order="changeOrder"
          @click="pushToTemplateShow"
        />
      </div>
    </div>
    <announce-popup
      v-if="mixinSortPopup.showFlg"
      :type="mixinSortPopup.type"
      :title="mixinSortPopup.title"
      :buttons="mixinSortPopup.buttons"
      @close="mixinCloseSortPopup"
      >{{ mixinSortPopup.message }}</announce-popup
    >
    <unsaved-leave-popup />
  </div>
</template>

<script>
import BaseButtonRegister from '@/components/parts/atoms/BaseButtonRegister'
import BaseSortButton from '@/components/parts/atoms/BaseSortButton'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import BaseButtonSmallOrange from '@/components/parts/atoms/BaseButtonSmallOrange'
import ListTable from '@/components/parts/organisms/ListTable'
import { mapGetters } from 'vuex'
import { removeHtmlTag } from '@/utils/convert_string'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import ApiOrder from '@/components/mixins/ApiOrder'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'

export default {
  name: 'MedicalContentTemplates',
  components: {
    BaseButtonRegister,
    BaseSortButton,
    BaseButtonSmallWhite,
    BaseButtonSmallOrange,
    ListTable,
    AnnouncePopup,
    UnsavedLeavePopup
  },
  mixins: [ApiOrder, CheckInputDifference],
  data() {
    return {
      headers: ['テンプレート名', '内容'],
      properties: ['name', 'content'],
      styles: [{ width: '30%' }, { width: '70%' }],
      waitFlg: false
    }
  },
  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg',
      medicalContentTemplates: 'medicalContentTemplates/getData'
    }),
    displayData() {
      const data = this.mixinSortModeFlg
        ? this.mixinInputData
        : this.medicalContentTemplates
      return data.map(v => {
        return {
          id: v.id,
          name: v.name,
          content: removeHtmlTag(v.content)
        }
      })
    }
  },
  watch: {
    mixinSortModeFlg: function() {
      this.setData()
    }
  },
  created() {
    this.setData()
  },
  methods: {
    setData() {
      this.mixinInputData = this.medicalContentTemplates
      this.mixinSetInitialData()
    },
    setSortMode() {
      this.mixinSortModeFlg = true
    },
    cancelSortMode() {
      this.mixinSortModeFlg = false
    },
    async updateOrder() {
      this.waitFlg = true
      await this.mixinUpdateOrder(
        'medicalContentTemplates',
        this.mixinInputData
      )
      if (this.mixinSortSucceeded) this.mixinSetInitialData()
      this.waitFlg = false
    },
    changeOrder(data) {
      this.mixinInputData = data.map(v1 =>
        this.mixinInputData.find(v2 => v2.id === v1.id)
      )
    },
    pushToTemplateShow(id) {
      this.$router.push({
        path: `/settings/template/medical-content-templates/${id}`
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.medical-content-templates {
  > .content {
    margin-top: 20px;
    margin-bottom: 24px;
    > .area {
      &.buttons {
        display: flex;
        justify-content: flex-end;
        > .button {
          margin-left: 20px;
        }
      }
      &.list {
        margin-top: 16px;
        > .heading {
          margin-bottom: 16px;
          font-size: 15px;
          font-weight: bold;
        }
        ::v-deep .list-table .table-body {
          max-height: 560px;
        }
      }
    }
  }
}
</style>
