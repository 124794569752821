<template>
  <div class="estimate-templates">
    <div class="content">
      <div class="area buttons">
        <base-button-register
          v-if="lookOnlyFlg === 0"
          :text="'新規登録'"
          @click="goToNewPage"
        />
      </div>
      <div class="area list">
        <div class="heading">見積書備考テンプレート一覧</div>
        <list-table
          :directOrderFlg="true"
          :selectBoxFlg="false"
          :headerData="headers"
          :propertyData="properties"
          :bodyData="estimateTemplates"
          :headerItemStyleData="styles"
          :bodyItemStyleData="styles"
          @click="goToShowPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseButtonRegister from '@/components/parts/atoms/BaseButtonRegister'
import ListTable from '@/components/parts/organisms/ListTable'
import { mapGetters } from 'vuex'

export default {
  name: 'EstimateTemplates',

  components: {
    BaseButtonRegister,
    ListTable
  },

  data() {
    return {
      headers: ['テンプレート名', '内容'],
      properties: ['name', 'content'],
      styles: [{ width: '30%' }, { width: '70%' }]
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg',
      estimateTemplates: 'estimateTemplates/getData'
    })
  },
  methods: {
    goToNewPage() {
      this.$router.push({ name: 'estimate-template-new' })
    },
    goToShowPage(id) {
      this.$router.push({ path: `estimate-templates/${id}` })
    }
  }
}
</script>

<style lang="scss" scoped>
.estimate-templates {
  > .content {
    margin-top: 20px;
    margin-bottom: 24px;
    > .area {
      &.buttons {
        display: flex;
        justify-content: flex-end;
      }
      &.list {
        margin-top: 16px;
        > .heading {
          margin-bottom: 16px;
          font-size: 15px;
          font-weight: bold;
        }
        ::v-deep .list-table .table-body {
          max-height: 560px;
        }
      }
    }
  }
}
</style>
